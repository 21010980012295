import { useNavigate } from '@tanstack/react-router'
import { useCallback } from 'react'

export function useNavigateToSubscriptionFunnel(): (subscriptionId: string) => void {
  const navigate = useNavigate()
  return useCallback(
    (subscriptionId: string) => {
      void navigate({ to: '/subscribe/$subscriptionId/$stepId', params: { subscriptionId, stepId: 'quote' } })
    },
    [navigate],
  )
}
